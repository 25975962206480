// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/Index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-polytic-js": () => import("./../../../src/templates/Polytic.js" /* webpackChunkName: "component---src-templates-polytic-js" */),
  "component---src-templates-post-post-jsx": () => import("./../../../src/templates/Post/post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-templates-tag-index-jsx": () => import("./../../../src/templates/Tag/index.jsx" /* webpackChunkName: "component---src-templates-tag-index-jsx" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/Tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

