import React from "react";
import i18next from "i18next";
import * as ReactI18next from "react-i18next";

export const AlternateLinksContext = React.createContext([]);

export function wrapWithI18nProvider({ element, props }) {
  const i18n = i18next
    .createInstance({
      lng: props.pageContext.language,
      interpolation: { escapeValue: false },
      initImmediate: false,
      resources: props.pageContext.i18nResources,
      pageContext: props.pageContext //необходимо переделать!! не смог передать pageContext в компонент Blog
    })
    .use(ReactI18next.initReactI18next);
  // noinspection JSIgnoredPromiseFromCall
  i18n.init();
  return (
    <ReactI18next.I18nextProvider i18n={i18n}>
      <AlternateLinksContext.Provider
        value={props.pageContext && props.pageContext.alternateLinks}
      >
        {element}
      </AlternateLinksContext.Provider>
    </ReactI18next.I18nextProvider>
  );
}
